import * as types from "./types";

export const searchMatches = data => ({
  type: types.SEARCH_MATCHES,
  payload: data,
  meta: {
    async: true,
    blocking: true,
    path: `/matches/search`,
    method: "POST",
    body: data
  }
});

export const searchMatchesV2 = data => ({
  type: types.SEARCH_MATCHES,
  payload: data,
  meta: {
    async: true,
    blocking: true,
    path: `/matches/v2/search`,
    method: "POST",
    body: data
  }
});

export const submitMatches = data => ({
  type: types.SUBMIT_MATCHES,
  meta: {
    async: true,
    blocking: true,
    path: `/matches/submit`,
    method: "POST",
    body: data
  }
});

export const searchClickOutsMatches = data => ({
  type: types.SEARCH_CLICKOUTS_MATCHES,
  payload: data,
  meta: {
    async: true,
    blocking: true,
    path: `/matches/clickouts/search`,
    method: "POST",
    body: data
  }
});

export const submitClickoutsMatches = data => ({
  type: types.SUBMIT_CLICKOUTS_MATCHES,
  meta: {
    async: true,
    blocking: true,
    path: `/matches/clickouts/submit`,
    method: "POST",
    body: data
  }
});

export const resetMatchSearch = () => ({
  type: types.RESET_MATCH_SEARCH
});
