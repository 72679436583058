import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { messengerAppId, referralLink } from "../../../config";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";

const CardWrapper = styled.div`
  border-bottom: 1px solid #ddd;

  .refer-container {
    padding-bottom: 30px;
    h3 {
      color: #444444;
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;

    @media (max-width: 375px) {
      gap: 5px;
    }
  }

  .refer-text {
    font-size: 14px;
    color: #888888;
    line-height: 19px;
    font-family: "Open Sans", sans-serif !important;
  }

  .copy-link {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      margin-right: 10px;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-weight: 800;
      color: #888888;
      width: 250px;
      height: 34px;
      box-sizing: border-box;
    }

    button {
      background: #eb671c;
      padding: 5px 16px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 4px;
      height: 34px;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    button:hover {
      background-color: #0056b3;
    }

    @media (max-width: 500px) {
      flex-direction: row;
      width: 100%;

      input {
        width: 57%;
        margin-right: 5px;
      }

      button {
        width: 43%;
        padding: 5px;
      }
    }
  }
`;

const StyledFacebookIcon = styled(FacebookIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #0965fe;
  }
`;

const StyledFacebookMessengerIcon = styled(FacebookMessengerIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #0084ff;
  }
`;

const StyledTwitterIcon = styled(XIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #000000;
  }
`;

const StyledTelegramIcon = styled(TelegramIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #25a3e3;
  }
`;

const StyledWhatsappIcon = styled(WhatsappIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #25d366;
  }
`;

const StyledLinkedinIcon = styled(LinkedinIcon)`
  border-radius: ${props => (props.round ? "50%" : "0")};
  transition: border-radius 0.3s ease;

  &:hover {
    border-radius: 0;
    background-color: #0077b5;
  }
`;

const FriendRefer = () => {
  const [buttonText, setButtonText] = useState("Copy Link");
  const [iconSize, setIconSize] = useState(40);

  const handleClickSocialPlatForm = SocialPlatForm => {
    createGTMEvent({
      event: "Step Conversion",
      label: SocialPlatForm,
      action: "Click"
    });
  };
  useEffect(() => {
    const handleIconSize = () => {
      if (window.innerWidth < 400) {
        setIconSize(30);
      } else {
        setIconSize(40);
      }
    };
    window.addEventListener("resize", handleIconSize);
    handleIconSize();

    return () => window.removeEventListener("resize", handleIconSize);
  }, []);

  const copyToClipboard = () => {
    handleClickSocialPlatForm("Refer a friend copy link");
    navigator.clipboard.writeText(referralLink);
    setButtonText("Copied Link");
    setTimeout(() => setButtonText("Copy Link"), 2000);
  };

  return (
    <CardWrapper>
      <div className="refer-container">
        <h3>Refer a Friend:</h3>
        <p className="refer-text">
          Know someone else looking for their perfect match? Refer them now and
          help them find their ideal university too.
          <br /> Just share the link below, and they'll get access to the same
          personalized matching process!
        </p>

        <div className="social-icons">
          <FacebookShareButton
            onClick={() => handleClickSocialPlatForm("Refer a friend Facebook")}
            url={referralLink}
          >
            <StyledFacebookIcon size={iconSize} round={true} />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            onClick={() =>
              handleClickSocialPlatForm("Refer a friend Messenger")
            }
            url={referralLink}
            appId={messengerAppId}
          >
            <StyledFacebookMessengerIcon size={iconSize} round={true} />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            onClick={() => handleClickSocialPlatForm("Refer a friend X")}
            url={referralLink}
          >
            <StyledTwitterIcon size={iconSize} round={true} />
          </TwitterShareButton>

          <TelegramShareButton
            onClick={() => handleClickSocialPlatForm("Refer a friend Telegram")}
            url={referralLink}
          >
            <StyledTelegramIcon size={iconSize} round={true} />
          </TelegramShareButton>

          <WhatsappShareButton
            onClick={() => handleClickSocialPlatForm("Refer a friend Whatsapp")}
            url={referralLink}
          >
            <StyledWhatsappIcon size={iconSize} round={true} />
          </WhatsappShareButton>

          <LinkedinShareButton
            onClick={() => handleClickSocialPlatForm("Refer a friend Linkedin")}
            url={referralLink}
          >
            <StyledLinkedinIcon size={iconSize} round={true} />
          </LinkedinShareButton>
        </div>

        <div className="copy-link">
          <input type="text" value={referralLink} readOnly />
          <button className="cta_link" onClick={copyToClipboard}>
            {buttonText}
          </button>
        </div>
      </div>
    </CardWrapper>
  );
};

export default FriendRefer;
